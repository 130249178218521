<template>
<b-collapse id="more-info" class="mx-auto">
    <b-row>
        <img class="headshot mx-auto" src="@/img/headshot.png" alt="">
    </b-row>
    <b-row>
        <b-col md="6" class="bio-col">
            <h3>Bio</h3>
            <p class="bio-text">I am 32 years old, born and raised in the dark parts of Vendyssel. Around 8 years ago I moved to Aalborg with my girlfriend and we recently bought our first house, where we live with our three year old daughter. In my spare time I like to run as often as my old and fragile knees will allow. Besides that, I like to write small NodeJS applications and tinkering with my Raspberry Pi and miscellaneous IOT devices.
            <br><br>
            Ever since I got my first computer, I found interest for every facet of creativity on a screen. I started out
            Photoshop, Illustrator, Indesign, 3D studio MAX, After Effects, HTML and CSS coding, you name it.
            <br><br>
            Today I focus all my energy on digital design, where I apply a logic and practical approach, to make meaningful products for the intended user.
            </p>
        </b-col>
        <b-col md="6" class="bio-col">
            <h3>Work and Education</h3>
        <b-row>
            <b-col md="6" class="cv-item">
                <h5>CX Designer</h5>
                <p>At Inwido Denmark A/S</p>
            </b-col>
            <b-col md="6" class="cv-item">
                <h5>Digital Designer</h5>
                <p>At Vinduesgrossisten.dk (Skanva Group)</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col md="6" class="cv-item">
                <h5>BA degree</h5>
                <p>In digital conceptdevelopment from UCN</p>
            </b-col>
            <b-col md="6" class="cv-item">
                <h5>AP in Graphic Design</h5>
                <p>At University College Nordjylland</p>
            </b-col>
        </b-row>
        </b-col>
    </b-row>
</b-collapse>
</template>

<script>

export default {
    name: 'MoreInfo'
      
}
</script>

<style scoped lang="scss">
    #more-info {
        margin-top: 64px;
    }
    img.headshot {

        width: 128px;
        height: 128px;
        margin-bottom: 64px;
     }
        

    h3 {
        margin-bottom: 24px;
    }
    .bio-text {
        max-width: 480px;
    }
    .cv-item {
        margin-bottom: 32px;
        p {margin-bottom:0;}
    }

    @media (max-width: 767px) {
    .bio-col {
        margin-bottom: 48px;
    }
    .headshot {
        margin-bottom: 32px;
    }
}

</style>