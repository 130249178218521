<template>
<div>
	<b-container>
		<NavAlt/>
	</b-container>
	<Header 
		title="B2C webshop redesign" 
		subtitle="Complete overhaul of shop design"
	/>

	<b-container class="section">
		<b-row>
			<b-col md="4">
				<b-img :src="require('@/img/skanva/skanva3.jpg')" fluid alt=""></b-img>
			</b-col>
			<b-col md="8">
				<h2>Background</h2>
				<p>During my employment at Skanva Group, I worked mainly on redesigning their ecommerce platform, where they sell windows and doors. This included creating a completely new visual identity for them where the main goal was to create a up to date and sleek style that still reflected them being a value-for-money brand. </p>
				<p>The redesign was a part of a very comprehensive task of migrating from a Magento 1.9 to 2.0 platform and during the process I worked closely with the external developers on both the design part, ensuring deliverables for handover were produced correctly, but also acting in a product manager role, scoping out the feature that Skanva needed and  communicating them to the developers. </p>
            	<p><a href="https://vinduesgrossisten.dk/">Visit the site here</a></p>
			</b-col>
		</b-row>
        
	</b-container>

    <b-container class="section">
        <b-row>
            <b-col md="6">
                <b-img :src="require('@/img/skanva/skanva2.png')" fluid alt=""></b-img>
            </b-col>
			<b-col md="6">
                <b-img :src="require('@/img/skanva/skanva4.png')" fluid alt=""></b-img>
            </b-col>
        </b-row>
	</b-container>
	<b-container class="section">
		<b-row>
			<b-col md="12">
				<h2>Guerilla user research</h2>
				<p>Occasionally you can get creative with how you gather user data. For example when we were redesigning the contact page for the site, I realised that we could take form entries from the existing site and sort all the questions and inquiries that the users had into categories and then use those insights to figure out how the page should be structured and use that as a basis for the wireframing process. </p>
			</b-col>		
		</b-row>
		<b-row>
			<b-col med="12">
				<b-img :src="require('@/img/skanva/skanva5.jpg')" fluid alt=""></b-img>
			</b-col>
		</b-row>
	</b-container>


</div>
</template>

<script>
import Header from '../components/Header.vue'
import NavAlt from '../components/NavAlt.vue'
import { LightGallery } from 'vue-light-gallery'


export default {
  name: 'just-go',
  components: {
    Header,
	NavAlt,
	LightGallery
  },
  data: function () {
    return {
		images: [
          { url: 'img/cases/just-go/mockup1.png' },
          { url: 'img/cases/just-go/mockup2.png' },
		  { url: 'img/cases/just-go/mockup3.png' },
		  { url: 'img/cases/just-go/mockup4.png' },
		  { url: 'img/cases/just-go/mockup5.png' },
		  { url: 'img/cases/just-go/mockup6.png' },
        ],
        index: null,

    };
  }
}
</script>

<style lang="scss" scoped>
	.gallery {
		display: flex;
		flex-wrap: wrap;
	}
	.thumb {
		cursor: pointer;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
</style>