<template>
<div id="app">

	<b-container fluid>
		<router-view/>
	</b-container>

	<b-container>
		<Footer/>
	</b-container>

</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'

export default {
  name: 'app',
  components: {
	Nav,
	Footer
  }
}

</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,600;1,600&family=Raleway:wght@400;600&display=swap');

body {
	background: $black !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}
.container-fluid {
	padding-left: 0px !important;
	padding-right: 0px !important;
}
.section {
	margin-top: 128px;
}
	@media (max-width: 768px) {
	.section {
		margin-top: 64px;
		margin-bottom: 48px;
	}
}
@media (max-width: 767px) {
	.col-md-4, .col-md-6, .col-md-8 {
	margin-top: 16px;
	}
}

#app {
	color: $white;
}

body .modal-content {
	background-color: $black;
	color: $white;
}
body .close {
	color: $white;
	opacity: 1;
}

</style>
