<template>
<div>
    <div class="space-hack"></div>
    <b-row>
        <b-col md="6" class="skill">
            <b-row>
                <b-col md="4" class="skill-icon d-flex justify-content-end align-items-center">
                    <img src="@/img/icons/iconDesign.svg" alt="">
                </b-col>
                <b-col md="8" class="skill-text">
                    <h3>Digital Design</h3>
                    <p>Through iteration I turn data into sketches and wirefames, which in the end become pixels. My weapon of choice is Sketch, but am well-versed in other common tools such as Adobe XD, Principle, Illustrator, Photoshop, etc.</p>
                </b-col>
            </b-row>
        </b-col>
        <b-col md="6" class="skill">
            <b-row>
                <b-col md="4" class="skill-icon d-flex justify-content-end align-items-center">
                    <img src="@/img/icons/iconUsers.svg" alt="">
                </b-col>
                <b-col md="8" class="skill-text">
                    <h3>User research</h3>
                    <p>Happy and well-informed users are good customers. This is why I subscribe to the philosophy behind user-centred design and apply it to every possible aspect of my design-process.</p>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
    <b-row>
        <b-col md="6" class="skill">
            <b-row>
                <b-col md="4" class="skill-icon d-flex justify-content-end align-items-center">
                    <img src="@/img/icons/iconStats.svg" alt="">
                </b-col>
                <b-col md="8" class="skill-text">
                    <h3>Analysis</h3>
                    <p>Data equals insight. I am skilled in Google Analytics, Tag Manager, and Data Studio. Through tools like these I interpret quantitative data into useful information about the users.</p>
                </b-col>
            </b-row>
        </b-col>
        <b-col md="6" class="skill">
            <b-row>
                <b-col md="4" class="skill-icon d-flex justify-content-end align-items-center">
                    <img src="@/img/icons/iconCode.svg" alt="">
                </b-col>
                <b-col md="8" class="skill-text">
                    <h3>Coding</h3>
                    <p>I speak fluent HTML and CSS. This means I can deliver realistic and production-ready design that developers can painlessly translate to functioning products.</p>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</div>
</template>

<script>

export default {
    name: 'Skills'
      
}
</script>

<style scoped lang="scss">
    .space-hack {
        height: 64px;
    }
    .skill-icon {
        img {
            width: 64px;
            height: 64px;
        }
        
    }
    @media (min-width: 768px) {
        .row:nth-of-type(1) {
            margin-bottom: 32px;
        }
    }

    @media (max-width: 768px) {
        .skill {
            margin-bottom: 56px;

        }
        .skill-icon {
            justify-content: center !important;
        }
        .skill-text {
            text-align: center;
            margin-top: 16px;
            padding-left: 32px;
            padding-right: 32px;
        }
    }


</style>