<template>
<div>
	<b-container>
		<Nav/>
	</b-container>
	<Header 
		title="Hi, I am a UX designer from Aalborg, Denmark" 
		subtitle="I convert user data and caffeine into pixels."
	/>
	<b-container>
		<div  class="col-lg text-center">
		<b-button id="readmore" v-b-toggle="'more-info'" class="btn btn-green animate fadeIn ani-delay1000">More about me</b-button>
	</div>

	<MoreInfo/>

	</b-container>

	<b-container id="cases" class="section animate fadeIn">
		<Cases/>
	</b-container>

	<b-container id="skills" class="section">
		<Skills/>
	</b-container>

	<b-container fluid class="section">
		<Quote/>
	</b-container>

</div>
</template>

<script>
	import Nav from '../components/Nav.vue'
	import Header from '../components/Header.vue'
	import MoreInfo from '../components/MoreInfo.vue'
	import Cases from '../components/Cases.vue'
	import Skills from '../components/Skills.vue'
	import Quote from '../components/Quote.vue'

	export default {
		name: 'Home',
		components: {
			Nav,
			Header,
			MoreInfo,
			Cases,
			Skills,
			Quote
		}
	}
	
</script>

<style lang="scss" scoped>
	@media (max-width: 767px) {
    .col {
		margin-top: 16px;
	}
}
</style>