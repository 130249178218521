<template>
<div>
	<b-container>
		<NavAlt/>
	</b-container>
	<Header 
		title="Social cycling app UX design" 
		subtitle="Freelance work for Snapp A/S"
	/>

	<b-container class="section">
		<b-row>
			<b-col md="12">
				<h2>Background</h2>
			</b-col>
		</b-row>
		<b-row>
			<b-col md="6">
				<p>In this project, I was hired in to do UX design for an app that Snapp were planning to create with an external client. The idea behind the app is to act as a social platform that cyclist can create and find cycling events that are tailored to their exact preferences.</p>
            	<p>I worked closely with their developers and their client to ensure that the design would meet both the cliens demands and the technologies that would lie behind.</p>
            	<p>The final product onboards the user with their unique cycling preferences, and utilizes design patterns from the iOS calendar app for them to select when they want to participate in events.</p>
			</b-col>
			<b-col md="6">
				<b-img :src="require('@/img/just-go/wireframes1.jpg')" fluid alt=""></b-img>
			</b-col>
		</b-row>
	</b-container>
	
	<b-container class="section">
		<b-row>
			<b-col md="6">
				<b-img :src="require('@/img/just-go/wireframes2.jpg')" fluid alt=""></b-img>
			</b-col>			
			<b-col md="6">
				<h2>Using familiar interactions</h2>
				<p>As part of the onboarding process, users are asked to choose what times and which weekdays they are available for events, to easily filter all available events. This presents a set of problems in firstly to show a simple overview of the weekdays, and also how the user is able to create a new timeslot for multiple days at the same time.</p>
				<p>This is where I used the iOS calendar design pattern of having the days represented by a circle, with a small dot underneath when there are timeslots created for that particular day.</p>
				<p>This pattern will also be used for the modal that creates new timeslots, where the user can select multiple day circles that the new timeslot should be active for.</p>
			</b-col>
			
		</b-row>
	</b-container>

	<b-container class="section">
		<div>
			<LightGallery
			:images="images"
			:index="index"
			:disable-scroll="true"
			@close="index = null"
			/>
			<b-row>
				<b-col cols="6" md="4" class="thumb" 
					v-for="(thumb, thumbIndex) in [
					'img/cases/just-go/mockup1.png',
					'img/cases/just-go/mockup2.png',
					'img/cases/just-go/mockup3.png',
					'img/cases/just-go/mockup4.png',
					'img/cases/just-go/mockup5.png',
					'img/cases/just-go/mockup6.png',
					]"
					:key="thumbIndex"
					@click="index = thumbIndex"
					>
					<img :src="thumb">

				</b-col>
			</b-row>
	
		</div>
	</b-container>


</div>
</template>

<script>
import Header from '../components/Header.vue'
import NavAlt from '../components/NavAlt.vue'
import { LightGallery } from 'vue-light-gallery'


export default {
  name: 'just-go',
  components: {
    Header,
	NavAlt,
	LightGallery
  },
  data: function () {
    return {
		images: [
          { url: 'img/cases/just-go/mockup1.png' },
          { url: 'img/cases/just-go/mockup2.png' },
		  { url: 'img/cases/just-go/mockup3.png' },
		  { url: 'img/cases/just-go/mockup4.png' },
		  { url: 'img/cases/just-go/mockup5.png' },
		  { url: 'img/cases/just-go/mockup6.png' },
        ],
        index: null,

    };
  }
}
</script>

<style lang="scss" scoped>
	.gallery {
		display: flex;
		flex-wrap: wrap;
	}
	.thumb {
		cursor: pointer;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
</style>