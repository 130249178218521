<!-- :style="{ background: 'rgba(0,0,' + index*10 + ',1)' }" -->

<template>
<div>
    <b-container style="margin-top: 100px;">
            <div v-for="index in cellCounter" :key="index" :class="['paint-cell-' + index]" class="paint-cell">

                <!-- {{ count=+index }} -->

            </div>

            
    </b-container>
    <div class="clearer"></div>
</div>

</template>
<script>
export default {
      data: function() {
		return {
			cellCounter: 360
	    };
      }
}
</script>

<style lang="scss" scoped>
    .paint-cell {
        
        height: 50px;
        width: 50px;
        float: left;
        border-radius: 50%;
    }
    .clearer {
        clear: both;	
    }

    $i: 1;
    @while $i < 361 {
        .paint-cell-#{$i} {
            //background: rgba( (255-($i*10)) ,0,($i*20),1);
            background-color: hsl($i, (random(80) + 20), 50%);
        }
        $i: $i + 1;
    }
</style>