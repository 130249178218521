<template>
<div>
	<b-container>
		<NavAlt/>
	</b-container>
	<Header 
		title="Concept work for campaign site" 
		subtitle="Showcasing design and functionality"
	/>

	<b-container class="section">
		<b-row>
			<b-col md="12">
				<h2>Background</h2>
			</b-col>
		</b-row>
		<b-row>
            <b-col md="6">
                    <video width="100%" autoplay loop muted>
                    <source :src="require('@/img/colour-slider.mp4')" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
			</b-col>
			<b-col md="6">
                <p>I worked on this project as part of my job at Inwido, where my role in the project was mainly conceptualising the interactive elements on the site and taking the initial design mockups from our art director and making responsive versions of the site. The intention was to make a site that showcases a uniquely designed door in an unique and interesting way.</p>
            	<p>The site includes a video with a 3D rendering of the door that plays when the user scrolls down the page.</p>
                <p><a href="https://outrupdoeren.dk/">View the site here</a></p>
            	
			</b-col>
			
		</b-row>
	</b-container>
	
	<b-container class="section">
		<b-row>
			<b-col md="12">
				<h2>Visualizing for stakeholders</h2>
			</b-col>
		</b-row>
		<b-row>
            <b-col md="6">
				<p>Early on in the project, I realised it was very difficult to explain how I wanted the scroll effect to work and that people interpreted my explanation in many different ways. I decided to solve this by making a mockup video with a generic 3D model in Blender and make a dummy website, where I actually got the video to play on scroll using some javascript code that I found online.</p>
                    <p>Interestingly enough, the final site uses individual image frames instead of an actual video, since there are a lot of compatibility issues with getting video to play on scroll. </p>
            </b-col>
			<b-col md="6">
				<b-img :src="require('@/img/outrup/3d.png')" fluid alt=""></b-img>
			</b-col>
		</b-row>
	</b-container>



</div>
</template>

<script>
import Header from '../components/Header.vue'
import NavAlt from '../components/NavAlt.vue'
import { LightGallery } from 'vue-light-gallery'


export default {
  name: 'just-go',
  components: {
    Header,
	NavAlt,
	LightGallery
  },
  data: function () {
    return {
		images: [
          { url: 'img/cases/just-go/mockup1.png' },
          { url: 'img/cases/just-go/mockup2.png' },
		  { url: 'img/cases/just-go/mockup3.png' },
		  { url: 'img/cases/just-go/mockup4.png' },
		  { url: 'img/cases/just-go/mockup5.png' },
		  { url: 'img/cases/just-go/mockup6.png' },
        ],
        index: null,

    };
  }
}
</script>

<style lang="scss" scoped>
	.gallery {
		display: flex;
		flex-wrap: wrap;
	}
	.thumb {
		cursor: pointer;
		overflow: hidden;
		img {
			width: 100%;
		}
	}
</style>