<template>
<div>
    <div v-for="cases in cases" :key="cases.title" class="case" >
        <router-link :to="cases.link">
            <div class="case-img" :style="{ backgroundImage: 'url(' + require('@/img/' + cases.image + '.jpg') + ')' }"></div>
            <div class="case-card">
                <h2>{{ cases.title }}</h2>
                <p>{{ cases.subtitle }}</p>
                <b-button class="btn btn-pink">{{cases.button}}</b-button>
                
            </div>

        </router-link>
    </div>
</div>
</template>

<script>
export default {
      name: 'Cases',

      data: function() {
		return {
			cases:
            [
				{
                    title: 'Social cycling app design',
                    subtitle: "Wireframing and design as freelance work",
                    image: "just-go-case",
                    link: "/just-go",
                    button:"View case"

                },
				{
                    title: 'B2B sales platform for carpenters',
                    subtitle: "UX and design for a complex web app",
                    image: "inwido-case",
                    link: "/inwido",
                    button:"View case"

                },
                {
                    title: 'B2C webshop redesign',
                    subtitle: "New visual identity and complete overhaul of shop design",
                    image: "vg-case",
                    link: "/skanva",
                    button:"View case"

                },
                {
                    title: 'Concept work for campaign site',
                    subtitle: "Ideation of a scrollable video concept.",
                    image: "outrup-case",
                    link: "/outrup",
                    button:"View case"

                },
                {
                    title: 'Web Design article',
                    subtitle: "A short piece i wrote about one of the pitfalls of landing page design (external link)",
                    image: "fold-article",
                    link: "/fold-article",
                    button:"Read article"

                },
			]
	    };
    }
}
</script>

<style lang="scss" scoped>

.case-img {
    padding-top: calc(532 / 1110 * 100%);
    margin-top: 100px;
    background-size: cover;   
}

.case-card {
    
    padding: 32px;
    margin-top: -10%;
    margin-left: 64px;
    background: $grey;
}
a {
    color: $white;
    &:hover {
        color: $white;
        text-decoration: none !important;
    }
}
@media (min-width: 767px) {
    .case-card {
        max-width: 512px;
    }
}
@media (max-width: 767px) {
    .case-card {
        width: 100%;
        text-align: center;
        margin-top: 0px;
        margin-left: 0px;
        padding: 16px;
        margin-right: 0px;
    }
}
</style>