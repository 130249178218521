<template>

<div class="header animate ani-duration1000 fadeIn">
    <div class="wrapper">
        <div class="intro">
                <h1 class="animate ani-delay250 fadeIn"> {{title}} </h1>
                <div class="breakline animate ani-delay500 fadeIn"></div>
                <p class="animate ani-delay750 fadeIn"> {{subtitle}} </p>
        </div>
    </div>
    
</div>

</template>

<script>
export default {
    name: 'Header',
    props: {
        title: String,
        subtitle: String,
    }
}
</script>

<style lang="scss" scoped>
.header {
    position: relative;
    padding-top: calc(1210 / 3595 * 100%);
    background-image: url('../img/header-background.svg');
    background-size: cover;
    background-position: center;
}
.wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.intro {
    max-width: 450px;

    h1 {
        padding-bottom: 16px;
    }

    p {
        padding-top: 16px;
        font-size: 18px;
        margin: 0 auto;
        width: 260px;
    }

    a {
        text-decoration: underline;
    }
    .breakline {
        width: 314px;
        height: 1px;
        background-color: $pink;
        margin: 0 auto;
    }
}

@media (max-width: 767px) {
    .header {
        min-height: 256px;
    }
    .intro {
        max-width: 320px;
        h1 {
            font-size: 24px;
            line-height: 36px;
            padding-bottom: 8px;
        }

        p {
            padding-top: 8px;
            font-size: 16px;
            width: 260px;
        }

        .breakline {
            width: 250px;
            height: 1px;
            background-color: $pink;
            margin: 0 auto;
        }
    }
}
</style>