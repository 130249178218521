<template>
<div id="contact" class="section">
	<b-row class="header">
		<b-col>
			<h2>Get in touch</h2>
		</b-col>
	</b-row>
	<b-row>
		<b-col md="3" class="contact-item">
			<a href="https://www.linkedin.com/in/michael-jensen-420957b6/" target="_blank" rel="noreferrer">
			<div class="contact-icon"><img src="@/img/icons/iconLi.svg" alt=""></div>
			LinkedIn
			</a>
		</b-col>

		<b-col md="3" class="contact-item">
			<a href="https://www.instagram.com/moarpie/?hl=en" target="_blank" rel="noreferrer">
			<div class="contact-icon"><img src="@/img/icons/iconInsta.svg" alt=""></div>
			@moarpie
			</a>
		</b-col>

		<b-col md="3" class="contact-item">
			<a href="mailto:michael@vendila.dk">
			<div class="contact-icon"><img src="@/img/icons/iconMail.svg" alt=""></div>
			michael@vendila.dk
			</a>
		</b-col>

		<b-col md="3" class="contact-item">
			<a href="tel:28914441">
			<div class="contact-icon"><img src="@/img/icons/iconPhone.svg" alt=""></div>
			+45 28 91 44 41
			</a>
		</b-col>

	</b-row>

	<b-row class="signoff">
		<b-col>
			<p>Handmade by Michael Jensen - 2021</p>

		</b-col>
	</b-row>

</div>
</template>

<script>
export default {
	name: "Footer"
}
</script>

<style lang="scss" scoped>
.header {
	margin-bottom: 64px;
	text-align: center;
}
.contact-item {
	/*min height so animation doesnt move surrounding content*/
	min-height: 61px;
	text-align: center;
	&:hover .contact-icon img {
		width: 28px;
		height: 28px;
	}
	&:hover > a {
		color: $green;
		text-decoration: underline;
	}
	a {
		color: $white;
	}
}
.contact-icon {
	margin-bottom: 8px;
	img {
		transition: ease-in 150ms;
		width: 24px;
		height: 24px;
	}
}
.signoff {
	margin-top: 128px;
	margin-bottom: 48px;
	text-align: center;
	font-size: 14px;
	color: #7D7D7D;
	p {
		margin-bottom: 32px;
	}
	a {
		font-weight: bold;
	}
}

@media (max-width: 767px) {
	.contact-item {
		margin-bottom: 32px;
	}
}
</style>