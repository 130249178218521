import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Test from '../views/Test.vue'
import JustGo from '../views/just-go.vue'
import Inwido from '../views/Inwido.vue'
import Skanva from '../views/Skanva.vue'
import Outrup from '../views/Outrup.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/test',
    name: 'Test',
    component: Test
  },
  {
    path: '/just-go',
    name: 'just-go',
    component: JustGo
  },
  {
    path: '/inwido',
    name: 'Inwido',
    component: Inwido
  },
  {
    path: '/skanva',
    name: 'Skanva',
    component: Skanva
  },
  {
    path: '/outrup',
    name: 'outrup',
    component: Outrup
  },
  {
    path: '/fold-article',
    beforeEnter(to, from, next) {
        // Put the full page URL including the protocol http(s) below
        window.location.replace("https://medium.com/@moarpie/where-above-the-fold-really-is-27794a81cbce")
    }
}
  
  

]

const router = new VueRouter({
  mode: 'history',
  routes
})
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0)
  next();
})
export default router
